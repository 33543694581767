import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import ToggleMute from "../components/ToggleMute"

export default function ContractorAgreementA() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold mt-4 ml-4">Contractor Agreement</h1>

        <p className="p-4 lead">
          <strong>5)</strong> Right Not to Work – In the event that I no longer
          wish to undertake the contract job in which I have booked on the
          Primarius App or PCWS, I acknowledge and agree that I must inform
          ALPHAVIBE no less than 48 hours prior to the commencement of the
          contract job failing which I shall be liable to ALPHAVIBE in the
          manner set out in Clause 7 of this Agreement.
          <br />
          <br />
          <strong>6)</strong> Right of Substitution – In the event that I am
          unable or unwilling to perform the services personally, I can arrange
          at my own expense entirely for another suitable person (hereafter
          known as the “Substitute”). The Substitute must be prequalified by
          ALPHAVIBE as an approved contractor and must be able to perform the
          services. I undertake that the Substitute shall execute the
          Acknowledgment of Substitution in the form set out in Appendix 1
          appended hereto. I understand that ALPHAVIBE shall continue to pay the
          quoted fee to me and that I shall be liable and responsible to pay the
          Substitute directly such fee for the relevant contract job which I
          have substituted, regardless of the contractor fees that I will be
          receiving from ALPHAVIBE. I agree that I have to inform ALPHAVIBE at
          least 48 hours in advance prior to substitution arrangement, failing
          which may result in penalty incurred as agreed upon in Clause 7 of
          this Agreement. In addition, I understand and agree that ALPHAVIBE
          shall not be obliged to accept any such Substitute without the
          Acknowledgment of Substitution and I shall be liable to ALPHAVIBE in
          the manner set out in Clause 7 of this Agreement.
          <br />
          <br />
        </p>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-b")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-start")}
          />
        </div>
      </div>
    </Background>
  )
}
